.home {

  #mediaContainer {

    .itemContainer {
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: -1;
      opacity: 0;

      .mediaContentContainer {
        width: 100vw;
        height: 100vh;
      }
    }
  }

  #pageContentContainer {
    color: white;

    .storyContentItem {
      width: 100vw;
      height: 100vh;

      .storyContent:hover,
      .storyContent:focus {
        & .slider-subtitle .glyphicon {
          left:10px;
          transition: left 0.2s;
        }
      }

      h3.category {
        width: 100%;
        color: white;
        font-size: 20px;
        margin: 0 27px 0 27px;
        @include screen-sm() {
          margin: 0 27px 10px 34px;
          font-size: 30px;
        }
        a {
          color: white;
          &:hover {
            color: white;
            text-decoration: underline;
          }
        }
      }

    }
    .slider-title,
    h2.message,
    .news-title h2 {
      @extend %h1-font-size;
      font-weight: 400;
      padding: 0;
      margin: 0;

      a {
        color: white;
        text-shadow: 0 0 9px rgba(0,0,0,0.25);
        text-decoration: none;

        &:hover, &:focus {
          cursor: pointer;
          cursor: hand;
        }
      }
    }

    h2.message {
      @include screen-sm() {
        margin-right:56px;
      }
      @include screen-md() {
        margin-right:112px;
      }
      @include screen-lg() {
        margin-right:224px;
      }
    }

    .slider-subtitle {
      color: #ffffff;
      font-size: 14px;

      @include screen-md() {
        font-size: 18px;
        margin-top: 9px;
      }

      &:hover, &:focus {
        cursor: pointer;
        cursor: hand;
        text-decoration: none;

        .glyphicon {
          left: 10px;
          transition: left 0.2s;
        }
      }

      .glyphicon {
        left: 0;
        top: 2px;
        transition: left 0.2s;
      }
    }

    .storyContent {
      margin:0 27px;
    }

    .news {

      img {
        border: none;
        margin-bottom: 25px;
        width: 100%;
        max-width: 600px;
        height: auto;
      }

      .news-title {
        color: inherit;
        display: block;
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 10px;
        text-align: left;
        text-decoration: none;

        @include screen-md() {
          font-size: 18px;
        }
         @include screen-lg() {
          font-size: 21px;
        }

        &:hover {
          cursor: pointer;
          cursor: hand;

          .glyphicon {
            left:10px;
            transition: left 0.2s;
          }

        }

        .glyphicon {
          left: 0;
          top: 2px;
          transition: left 0.2s;
        }

      }

      .news-text {
        p {
          font-size: 13px;
          @include screen-md() {
            font-size: 16px;
          }
        }
      }

      .storyContent {
        p {
          text-align: left;
        }
      }
    }

    .hidden-xs-down {
      border: 1px solid red;
    }

  }

  @keyframes moveArrow {
    0%, 100% {transform: translateY(0)}
    50% {transform: translateY(-10px)}
  }

  .downScroll {
    bottom: 0;
    display: inline-block;
    padding: 27px;
    pointer-events: none;
    position: fixed;
    right: 18px;
    text-align: right;
    z-index: 3;

    svg#upScrollButton {
      margin-right: 9px;
      transform: rotate(180deg);
      transition: all 0.2s ease-in-out;
      visibility: hidden;
    }


    svg#upScrollButton,
    svg#downScrollButton {
      pointer-events: auto;
      height: 50px;
      transition: opacity 0.2s ease-in-out;
      width: 50px;
      @include screen-sm {
        height: 75px;
        width: 75px;
      }

      &:hover {
        cursor: pointer;
        cursor: hand;
      }

      .scroll-circle {
        fill: rgba(255,255,255,0.3);
        // stroke: rgba(255,255,255,1);
        stroke-miterlimit: 10;
        // opacity: 0.3;
        transition: fill 0.2s ease-in-out;
      }

      &:hover .scroll-circle {
        fill: rgba(255,255,255,0.8);
        // opacity: 1;
        transition: fill 0.2s ease-in-out;
      }

    }

  }

  svg#sideScrollButton {
    pointer-events: auto;
    height: 50px;
    width: 50px;
    transition: all 0.2s ease-in-out;
    @include screen-sm() {
      height: 75px;
      width: 75px;
    }
    &:hover {
      cursor: pointer;
      cursor: hand;
    }
    .side-circle {
      fill: rgba(0,0,0,0.6);
      transition: fill 0.2s ease-in-out;
    }
    .side-arrow {
      fill: white;
    }
    &:hover .side-circle {
      fill: rgba(0,0,0,1);
    }
  }

  .arrow-animation {
    animation: moveArrow 1.5s infinite;
  }

  .award-badges {
    bottom: 0px;
    left: 27px;
    margin: 0;
    padding: 0 0 80px;
    position: absolute;
    width: 100%;
    
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    
    @include screen-xs {
      padding: 0 0 27px;
    }

    @include screen-sm {
      align-items: flex-start;
      flex-flow: row nowrap;
      width: calc(100% - 216px - 27px); // 100% - arrow width - right margin
    }

    &__badge {
      background-color: #333;
      border-radius: 8px;
      color: #fff;
      font-size: 15px;
      line-height: 100%;
      list-style: none;
      margin-bottom: 8px;
      padding: 5px 10px;
      text-align: center;
      
      display: flex;
      align-items: center;
      justify-content: center;
      
      @include screen-sm {
        font-size: 20px;
        line-height: 125%;
        margin-right: 15px;
        padding: 10px 20px;
      }

      svg {
        height: 40px;
        width: auto;
        
        @include screen-sm {
          height: 45px;
        }
      }

      &-text {
        margin: 0 15px;

        &--organization {
          font-weight: 400;
          margin: 0;
          padding: 0;
        }

        &--type {
          font-weight: 600;
          margin: 0;
          padding: 0;
          text-transform: uppercase;
        }
      }
    }
  }
}

.storyContent.flex-content {
  display: flex;
  align-items: center;
  padding-bottom: 40px; // move slide content up on slides with categories
  .side-link-arrow {
    margin-left: 40px;
    margin-top: 20px;
  }
}

// .admin-bar.home {
//   .award-badges {
//     padding: 0 0 112px;

//     @include screen-xs {
//       padding: 0 0 32px;
//     }
//   }
// }