#scrollToTop {
  bottom: 36px;
  color: $min_design-gray-light;
  cursor: pointer;
  font-size: 11px;
  font-weight: 700;
  left: auto;
  opacity: 0;
  padding:10px;
  position: fixed;
  right: 27px;
  text-align: center;
  transition: opacity .2s ease-in-out;
  top: auto;
  z-index: 5;
  width: 60px;
  height: 60px;
  &.show {
    opacity: 1;
  }
  #up-arrow {
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    height: 100%;
    transition: fill .2s ease-in-out;
    .circle-outline {
      fill: rgba($min_design-pink, 0.1);
      transition: fill 0.2s ease-in-out;
      &:hover {
        fill: $min_design-pink;
        transition: fill 0.2s ease-in-out;
      }
    }
    g.arrow polygon {
      pointer-events: none;
      fill: #000;
    }
  }
}

#category-page-list {
  display: flex;
  flex-wrap: wrap;
  align-items: left;
  justify-content: left;
  list-style: none;
  margin: 99px 0 0;
  padding: 0;
  width: 100%;
  @include screen-md() {
    width: 670px;
  }
  li {
    margin: 0;
    padding: 0;
  }
  li>a {
    display:inline-block;
  }
}

a.btn-more, .btn-more {
  padding: 20px 45px;
  border-radius: 0;
  border: 1px solid #000;
  color: #5e5b56;
  background-color: white;
  position: relative;
  transition: background-color 0.4s ease-in-out 0s;
  &:hover {
    background-color: #ededed;
    transition: background-color 0.4s ease-in-out 0.4s;
  }
}

.glyphicon.spinner {
  animation: spin 1s infinite linear;
  -webkit-animation: spin2 1s infinite linear;
  vertical-align: -1px;
  margin-left: 5px;
}
@keyframes spin {
  from { transform: scale(1) rotate(0deg);}
  to { transform: scale(1) rotate(360deg);}
}

@-webkit-keyframes spin2 {
  from { -webkit-transform: rotate(0deg);}
  to { -webkit-transform: rotate(360deg);}
}

.more-posts {
  margin: 45px 0;
  text-align: center;
  .more-posts-button {
    margin: 0 auto;

  }
}

.button-container {
  // margin: 100px auto 0;
  margin: 0 auto;
  text-align: center;
}

// Animated border button
.button.animation-button {
  background-color: rgba(0,0,0,0);
  border: 2px solid black;
  border-radius: 0;
  box-sizing: border-box;
  color: black;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  height: 51px;
  line-height: 51px;
  margin-top: 35px;
  min-width: 205px;
  padding: 0 20px;
  overflow: hidden;
  position: relative;
  transition: all .1s ease-out;
  // transition-delay: .2s;
  text-decoration: none;
  text-align: center;
  width: auto;
  z-index: 9;
  -webkit-appearance: none;
    &:after {
      background: $min_design-pink;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 100%;
      transition: all .1s ease-out;
      width: 100%;
      z-index: -1;
    }
    &:before {
      background: $min_design-pink;
      bottom: 0;
      content: '';
      height: 0;
      left: 50%;
      margin-left: -50%;//-85px;
      position: absolute;
      transition: all .1s ease-out;
      width: 100%;
    }
  &:hover {
    // background: $min_design-pink;
    // border: 1px solid $min_design-pink;
    box-sizing: border-box;
    color: #FFF;
    cursor: pointer;
    display: inline-block;
    height: 51px;
    line-height: 51px;
    min-width: 205px;
    overflow: hidden;
    padding: 0 20px;
    position: relative;
    transition: all .2s ease-out;
    // transition-delay: .2s;
    text-decoration: none;
    text-align: center;
    width: auto;
    z-index: 9;
    -webkit-appearance: none;
    &:before {
      background: $min_design-pink;
      bottom: 0;
      content: '';
      left: 0;
      margin-left: 0 !important;
      position: absolute;
      transition: all .2s ease-out;
      width: 100% !important;
    }

    &:after {
      background: $min_design-pink;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: all .2s ease-out;
      width: 100%;
      z-index: -1;
    }
  }
  &:active {
    &:after {
      background: darken($min_design-pink, 20%);
    }
  }
  &:focus {
    &:after {
      border-color: $min_design-pink;
    }
  }
}

// https://getcssscan.com/css-buttons-examples
a.underline-magic {
  background-color: rgba(51, 51, 51, 0.05);
  border-radius: 8px;
  border-width: 0;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  list-style: none;
  margin: 0;
  padding: 10px 12px;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
