$nav-height-lg : 130px;
$nav-height-sm : 110px;

.social-list { // this is seperated out so it can control the footer as well.
  margin: 0;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: auto;
  height: 75%;
  @include screen-sm() {
    height: 100%;
    margin: 0 9px 0 0;
  }
  li {
    display: inline-block;
    list-style-type: none;
    height: 20px;
    width: 20px;
    float: left;
    margin: 0 5px 5px 5px;
    svg g {
      height: 20px;
      width: auto;
    }
    &.facebook {
      margin-right: 9px;
    }
    a {
      width: 20px;
      height: 20px;
      display: block;
      text-align: center;
      transition: fill 0.2s ease-out;
      &:hover path {
        fill: $min_design-pink;
        transition: fill 0.2s ease-out;
      }
    }
  }
}


.home nav#navigation {
  position: fixed;
  width: 100%;
}


.scrolled nav#navigation {
  // background-color: rgba(255,255,255, 0.4);
  // box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
  // transition: background-color 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
}

nav#navigation {
  // transition: background-color 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
  background-color: transparent;
  height: $nav-height-sm;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include ie-fix-media-query() {
    position: relative;
    padding: 18px 18px;
  }
  @include screen-sm() {
    padding: 18px 18px;
    top: 0;
    left: 0;
    position: fixed;
  }
  @include screen-sm() {
    height: $nav-height-lg;
    padding: 18px 27px;
  }
  div {
    float: left;
  }
  svg#logo {

    @include ie-fix-media-query() {
      margin: 0;
      padding: 0;
      width: 50px;
      height: 50px;
    }
    @include screen-sm() {
      margin: 0;
      padding: 0;
      width: 90px;
      height: 90px;
    }
  }

  .right-nav {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menuButton {
    background-color: rgba(0,0,0,0.5);
    color: black;
    cursor: pointer;
    float: right;
    position: relative;
    transform: rotate(0deg);
    transition: transform .3s ease-in-out, border .3s ease-in-out, background-color .3s ease-in-out;
    @include ie-fix-media-query() {
      margin-left: 9px;
      width: 50px;
      height: 50px;
    }
    @include screen-sm() {
      height: 60px;
      margin-left: 18px;
      width: 60px;
    }
    &.open {
      // background-color: rgba(255,255,255,0.3);
      // border: 1px solid rgba(255,255,255,0.3);
      transform: rotate(90deg);
      transition: transform 0.3s ease-in-out, border 0.3s ease-in-out, background-color 0.3s ease-in-out;
      &:hover {
      }
    }
    &:hover {
      background-color: rgba(255,255,255,0.8);
      span {
        background-color: $min_design-pink;
      }
      span:nth-child(1) {
        @include ie-fix-media-query() {
          top: 38%;
          transform: translate(-50%, -100%);
        }
        @include screen-sm() {
          top: 37%;
          transform: translate(-50%, -200%);
        }
      }
      span:nth-child(2), &:nth-child(3) {
        @include ie-fix-media-query() {
          top: 50%;
        }
        @include screen-sm() {
          top: 50%;
        }
      }
      span:nth-child(4) {
        @include ie-fix-media-query() {
          top: 60%;
          transform: translate(-50%, 100%);
        }
        @include screen-sm() {
          top: 63%;
          transform: translate(-50%, 100%);
        }
      }

    }
    span {
      padding: 0;
      display: block;
      position: absolute;
      background-color: white;
      //border-radius: 3px;
      transform-origin: center center;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.2s ease-out;
      @include ie-fix-media-query() {
        width: 30%;
        height: 2px;
      }
      @include screen-sm() {
        height: 2px;
        width: 40%;
      }
      &:nth-child(1) {
        @include ie-fix-media-query() {
          top: 40%;
        }
        @include screen-sm() {
          top: 37%;
        }
      }
      &:nth-child(2), &:nth-child(3) {
        @include ie-fix-media-query() {
          top: 50%;
        }
        @include screen-sm() {
          top: 50%;
        }
      }
      &:nth-child(4) {
        @include ie-fix-media-query() {
          top: 60%;

        }
        @include screen-sm() {
          top: 63%;
        }
      }

      &.open {
        background-color: white;
        //transform : rotate(90deg);
        &:nth-child(1), &:nth-child(4) {
          width: 0;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        &:nth-child(2) {
          transform:  translate(-50%, -50%) rotate(45deg);

        }
        &:nth-child(3) {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }

  }

  ul {
    float: right;
  }
}

.home nav#navigation {
  background-color: transparent; // make transparent on homepage
}

nav#menu.offCanvas {
  // transform: translateX(-100%);
  // transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  z-index: 9;
  top: 0;
  left: 0;
  background-color: black;
  visibility: hidden;
  width: 100vw;
  padding-bottom: 40px;
  opacity: 0;
  position: absolute;
  &.active {
    opacity: 0.9;
    visibility: visible;
    height: auto;

  @include screen-sm() {
    height: 100vh;
  }
  }
  a {
    font-family: $min_design_font;
    color: #dedede;
    text-decoration: none;
    &:hover {
      color: white;
    }
  }
  .mainLinks {
    float: left;
    margin: 150px 0 0 18px;
    font-size: 30px;
    @include screen-sm() {
      margin: 157px 0 0 30px;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        @include screen-md() {

        }
      }
    }
    a {
      font-size: 20px;
      @include screen-md() {
        font-size: 20px;
      }
    }
  }
  li.menu-section-head {
    &:nth-of-type(2) {
      @include screen-sm() {
        margin-left: 70px;
      }
    }
    @include screen-sm() {
      float: left;
    }
    > a {
      color: $min_design-pink;
    }
  }
  ul.sub-menu {
    @include screen-sm() {
      margin-top: 20px;
    }
    li {
      margin-right: 20px;
      a {
        color: white;
        font-size: 22px;
        text-decoration: none;
        @include screen-sm() {
          font-size: 35px;
        }
        @include screen-md() {
          font-size: 50px;
        }
        @include screen-lg() {
          font-size: 75px;
        }
        &:hover {
          color: $min_design-pink;
        }
      }
    }
  }
}

body.home .wrap {
  margin-top: 0;
}

.wrap {
  @include screen-sm() {
    margin-top: $nav-height-lg;
  }
}

.page-template-template-project-category-php {
  #footer .social-list li svg g path {
    fill: white;
  }
}
