.about {

  .main {
    padding: 0 18px;
    @include screen-sm() {
      padding: 0 27px;
    }
  }

  .row .col-sm-6:nth-child(even).about-image {
    margin-top: 54px;
    @include screen-sm() {
      margin-top: 0;
    }
  }

  h2 {
    color: black;
    font-size: 25px;
    font-weight: 700;
    margin: 0 0 20px 0;
    @include screen-sm() {

      padding: 0;
    }
    @include screen-md() {
      font-size: 30px;
    }
    &.founder {
      color: black;
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 30px;
      margin-top: 30px;
      @include screen-sm() {
        margin-top: 0;
      }
      span {
        font-size: 15px;
        font-weight: 400;
        color: $min_design-gray-dark;
        margin-left: 20px;
      }
    }

  }

  .lecture-container {
    width: 100%;
    margin: 54px 0 153px 0;
    .lecture-text {
      @include screen-sm() {
        padding-left: 20px;
      }
    }
  }
  .lecture-text, .allied-text {
    margin-top: 25px;
    @include screen-sm() {
      margin-top: 0
    }
  }

  .awards-section {
    .awards {
      font-size: 16px;
      width: 100%;
      @include screen-sm() {
        columns: 400px 2;
        column-gap: 30px;
      }
    }
    .award-text {
      border-top: 1px solid $min_design-gray;
      margin-bottom: 20px;
      padding-top: 20px;
      .award-title {
        color: #000;
        font-size: 19px;
        font-weight: 400;
        margin: 0 0 5px;
      }
      a {
        color: $min_design-gray;
        display: inline-block;
        font-size: 15px;
        text-decoration: none;
        transition: all 0.2s;
        &:hover {
          color: $min_design-pink;
          transition: all 0.2s;
        }
      }
    }
  }


  .allied-practices {
    margin-top: 45px;
    margin-bottom: 60px;
  }

  img.mod-logo {
    width: 150px;
    height: auto;
    margin-bottom: 30px;
  }
  .allied-practices-image-container {
    position: relative;
    .rollover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      &:hover {
        opacity: 1;
      }
    }
  }

  .subtitle {
    margin: 36px 0 18px 0;
    font-size: 16px;
    // font-weight: 700;
  }

  .current-staff-container {
  width: 100%;
  .staff-member {
    border-top: 1px solid $min_design-gray;
    break-inside: avoid;
    margin-bottom: 20px;
    padding-top: 20px;
    width: 100%;
    @include screen-md() {
      display: inline-flex;
      flex-wrap: wrap;
      flex-flow: nowrap;
      width: calc(50% - 5px);
    }
    .staff-photo {
      height: 150px;
      margin-bottom: 15px;
      width: 150px;
      max-width: 100%;
      flex-shrink: 0;
      @include screen-md() {
        margin-right: 15px;
      }
    }
    .staff-meta {
      padding-right: 20px;
    }
    .staff-name {
      color: #000;
      font-size: 19px;
      font-weight: 400;
      margin: 0;
    }
    p {
      font-size: 14px;
    }
  }
}

  .partners {
    margin: 54px 0 60px;
    .about-image img {
      // filter: sepia(80%) hue-rotate(300deg) brightness(100%);
    }
  }

  .current-staff-container {
    margin: 0 0 120px;
  }

  .studio, .awards-container {
    margin: 54px 0 99px;
  }

  .all-link-container {
    width: 100%;
    margin: 20px 0;
    text-align:right;
  }


}
