.page-template-template-recognition {

	.recognition-section {
		margin-top: 90px;

		#awards {
			margin-top: 27px;
		}

		@include screen-md() {
			margin-top: 144px;

			#awards {
				margin-top: 63px;
			}
		}

	 .slick-slide {
	 	img {
			border: 1px solid $min_design-gray-light;
		}
       .slick-arrow {

       }
	 }

	 .recognition-title {
	 	color: $min_design-gray;
	 	font-size: 20px;
	 	margin-bottom: 18px;

	 	@include screen-md() {
			font-size: 33px;
	 	}

	 }

	 .slick-list {
	 	margin-left: -5px;
	 }

	}

	#awards {
		margin-top: 36px;

		@include screen-md() {
			margin-top: 72px;
		}

	}

}

.recognition-section {
  margin-top: 150px;
  .btn-more-container {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    .glyphicon {
    	padding-left: 7px;
    }
  }
}

.post-type-archive-min_recognition {
  .main {
    padding-right: 18px;
    padding-left: 18px;

    @include screen-sm() {
      padding-right: 27px;
      padding-left: 27px;
    }
  }
  .project-tiles-container {
    margin-top: 54px;
  }
  .button-container {
    text-align: right;
  }
}


/**
Recognition Grid
 */
.container-recognition-grid {
  position: relative;
  margin-top: 54px;
  margin-left: -5px;
  margin-right: -5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
  &.result {
    margin-top: 0;
  }
  .cell {
    padding: 0;
    position: relative;
    -ms-flex: 1 1 100%;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
    max-width: 100%;
    margin: 5px 5px 40px 5px;
    display: block;
    @include screen-sm() {
      display: flex;
      -ms-flex: 1 1 calc(50% - 10px);
      -webkit-flex: 1 1 calc(50% - 10px);
      flex: 1 1 calc(50% - 10px);
      width: calc(50% - 10px);
      max-width: calc(50% - 10px);
      margin: 5px 5px 40px 5px;
      align-items: flex-start;
    }

    .image {
      @include screen-sm() {
        -ms-flex: 1 1 calc(50% - 10px);
        -webkit-flex: 1 1 calc(50% - 10px);
        flex: 1 1 calc(50% - 10px);
        width: calc(50% - 10px);
        max-width: calc(50% - 10px);
        margin: 5px 15px 5px 0px;
      }
    }
    .content {
      @include screen-sm() {
        -ms-flex: 1 1 calc(50% - 10px);
        -webkit-flex: 1 1 calc(50% - 10px);
        flex: 1 1 calc(50% - 10px);
        width: calc(50% - 10px);
        max-width: calc(50% - 10px);
        margin: 20px 5px 0;
      }
      .top-section {
        margin: 15px 0 5px 0;
        font-size: 15px;
        a {
          color: $min_design-gray;
        }
        @include screen-sm() {
          margin: 0 0 5px 0;
        }
      }
      h2 {
        margin: 0 0 10px 0;
        line-height: 0.9em;
        a {
          font-size: 25px;
          color: black;
          &:hover {
            color: $min_design-pink;
            text-decoration: none;
          }
        }
      }
      .link-text {
        a {
          font-size: 15px;
        }
      }
    }

  }
}
