.tax-min_design_project_tags,
.post-type-archive-min_design_project,
.page-template-template-all-projects-page  {
  .project-tiles-container {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-top: 54px;
    @include screen-sm() {
      // margin-left: -5px;
      // margin-right: -5px;
    }
    &.result {
      margin-top: 0;
    }
    .project-tile-container {
      position: relative;
      
      &:hover .tile-content{
        @include screen-sm() {
          transform : translate(0, -36px);
         // margin-top: -36px;
          transition: transform 0.2s ease-out;
        }
      }
      a {
        display: block;
        &:hover {
          text-decoration: none;
        }
      }
      .tile-content {
        background-color: white;
        float: left;
        padding: 9px 0 18px;
        position: relative;
        width: 100%;
        z-index: 1;

        @include screen-sm() {
          margin-top: 0px;
          transition: transform 0.2s ease-out;
          transform : translate(0, 0);
        }
        .all-projects-tile-title {
          margin-bottom: 0;
          padding-right:10px;
          a {
            color: black;
            font-size: 18px;
            font-weight: 400;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            transition: color 0.2s ease-out;
            &:hover {
              color: $min_design-pink;
              transition: color 0.2s ease-out;
            }
          }
        }
        ul.all-project-category-list {
          color: $min_design-gray;
          display: block;
          float: left;
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            display: inline;
            float: left;
            margin-right: 10px;
            a {
              font-size: 14px;
              color: $min_design-gray;
              border-bottom: 1px solid $min_design-gray;
              transition: color .2s ease-out;
              &:hover {
                color: $min_design-pink;
                transition: color .2s ease-out;
              }
            }
          }

        }
      }
      $borderwidth: 6px;

      @include screen-sm() {
        width: 50%;
        flex-basis: 50%;
        max-width: 50%;
        flex-shrink: 1;
        flex-grow: 1;
        margin:5px 0;
        border-right: $borderwidth solid #fff;
        &:nth-child(2n) {
          border-right: 0;
        }
      }
      @include screen-md() {
        width: 33.3%;
        flex-basis: 33.3%;
        max-width: 33.3%;
        &:nth-child(2n) {
          border-right: $borderwidth solid #fff;
        }
        &:nth-child(3n) {
          border-right: 0;
        }
      }
      @include screen-lg() {
        width: 25%;
        max-width: 25%;
        flex-basis: 25%;

        &:nth-child(3n) {
          border-right: $borderwidth solid #fff;
        }
        &:nth-child(4n) {
          border-right: 0;
        }
      }

      &.project-wide {
        @include screen-sm() {
          width: 100%;
          flex-basis: 100%;
          max-width: 100%;
          flex-shrink: 1;
          flex-grow: 1;
          margin:5px 0;
          border-right: $borderwidth solid #fff;
          &:nth-child(2n) {
            border-right: 0;
          }
        }
        @include screen-md() {
          width: 33.3%;
          flex-basis: 33.3%;
          max-width: 33.3%;
          &:nth-child(2n) {
            border-right: $borderwidth solid #fff;
          }
          &:nth-child(3n) {
            border-right: 0;
          }
        }
        @include screen-lg() {
          width: 50%;
          max-width: 50%;
          flex-basis: 50%;
  
          &:nth-child(3n) {
            border-right: $borderwidth solid #fff;
          }
          &:nth-child(4n) {
            border-right: 0;
          }
        }
      }

      .project-tile-title {
        font-size: 18px;
        line-height: 1.2;
      }
    }

  }

}
