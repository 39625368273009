.page-template-template-curated_category-php {

  .main {
    padding: 0 18px;
    @include screen-sm() {
      padding: 0 27px;
    }
  }

  .project-tiles-container {
  	margin-top: 54px;
  }

$screen-min-height: 580px;
$grid-max-height: 70vh;

  .category-grid {
    @media(min-width: $screen-sm-min) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: 100vh; // minmax(270px,50vh);
      @media (min-height: $screen-min-height) {
        grid-auto-rows: minmax(270px,$grid-max-height);
      }
    }
  }

  .category-grid-item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    .project-bg {
      @include screen-sm() {
        max-width: 40vw;
      }
      @include screen-md() {
        max-width: 35vw;
      }
      @include screen-lg() {
        max-width: 30vw;
      }
    }
    &:nth-child(odd) .project-bg {
      @include screen-sm() {
        margin-left: auto;
        margin-right: 5vw;
      }
      // border:red 2px solid;
    }
    &:nth-child(even) .project-bg {
      @include screen-sm() {
        margin-right: auto;
        margin-left: 5vw;
        margin-top: 100vh;
        @media (min-height: $screen-min-height) {
          margin-top: $grid-max-height;
        }
      }
    }
  }

}
