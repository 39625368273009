.project-shares, .news-shares {
  margin: 25px 0 0 0;
  //margin: 0 auto;
  //text-align: center;
  ul.socials {
    font-size: 14px;
    float: left;
    height: 25px;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    display: flex;
    display: -webkit-flex;
    justify-content: left;
    -webkit-justify-content: left;
    @include screen-sm() {
      justify-content: flex-start;
      -webkit-justify-content: flex-start;
    }
    li {
      float: left;
      margin: 0 2px;
      border-radius: 3px;
      width: 30px;
      height: 25px;
      text-align: center;
      a {
        display: block;
        height: 100%;

      }
      &.label {
        color: $min_design-gray;
        font-size: 15px;
        width: auto;
        margin: 0 10px 0 0;
        padding: 0;
        span {

        }

      }
      &.email-share {
        svg {
          width: 15px;
          height: 15px;
          .cls-1 {
            fill: black;
            stroke: none;
          }
        }
        &:hover {
          svg .cls-1 {
            fill: $min_design-pink;
          }
        }
        //   background-color: #0589ff;
      }
      &.facebook-share {
        svg {
          width: 15px;
          height: 15px;
          path {
            fill: black;
          }
        }
        &:hover {
          svg path {
            fill: #316499;
          }
        }
        //background-color: #316499;
      }
      &.twitter-share {
        svg {
          width: 15px;
          height: 15px;
          .cls-1 {
            fill: black;
            stroke: none;
          }
        }
        &:hover {
          svg path {
            fill: #22c5f0;
          }
        }
        //background-color: #22c5f0;
      }
      img {
        display: inline-block;
        height: 12px;
        margin: 0 auto;
        width: auto;
      }
    }
  }
}
