footer.main-footer {
  width: 100%;
  float: left;
}
#footer {
  background: url('/wp-content/themes/min-design-theme/dist/images/footer-bg-img@2x-8.png') no-repeat;
  background-size: cover;
  height: auto;
  margin-top: 90px;
  padding: 80px 18px 18px;
  width: 100%;

  @include screen-md() {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
    height: auto;
  }

  .site-credit {
    a {
      border: 1px solid $min_design-gray-medium;
      color: $min_design-gray-medium;
      padding: 2px 5px;
      &:hover {
        border-color: black;
        color: black;
        text-decoration: none;
      }
    }
    @include screen-md() {
      flex:1;
      margin: 0 15px;
    }
  }

  .company-info {
      @include screen-md() {
        align-items: flex-end;
        display: flex;
        flex:1;
        justify-content: flex-end;
      }
  }

  .logo {
    margin: 0;
    padding: 0;
    a {
      display: block;
      position:relative
    }
    svg {
      display: block;
      height: 50px;
      width: 50px;
    }
    @include screen-xs() {
      // flex: 2 0 0;
    }
    @include screen-md() {
      svg {
        height: 50px;
        width: 50px;
      }
    }
  }

  .address {
    color: black;
    font-size: 13px;
    line-height: 1.3;
    list-style-type: none;
    margin-top: 18px;
    @include screen-md() {
      display: inline-block;
      font-size: 15px;
      line-height: 1.4;
      margin: 0 18px;
    }

  }
  .social-list {
    display: block;
    margin-top: 27px;
    padding-left: 0;
    svg {
      fill: black;
    }
    @include screen-md() {
      display: flex;
      align-items: left;
      justify-content: left;
      margin-top: 18px;
      padding-left: 40px;
      margin-top: 0;
    }
  }
}

.single-min_design_project {
  margin-top: 0;
  #footer {
    margin-top: 0;
  }
}

.page-template-template-project-category-php {
  #footer {
    // background-color: #333;
    // background-blend-mode: difference;
    .address {
      color: white;
    }
  }
}
