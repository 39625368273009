@font-face {
  font-family:'Circular Std';
  src: url('/wp-content/themes/min-design-theme/dist/fonts/CircularStd-Bold.eot');
  src: url('/wp-content/themes/min-design-theme/dist/fonts/CircularStd-Bold.eot?#iefix') format('embedded-opentype'),
  url('/wp-content/themes/min-design-theme/dist/fonts/CircularStd-Bold.woff2') format('woff2'),
  url('/wp-content/themes/min-design-theme/dist/fonts/CircularStd-Bold.woff') format('woff'),
  url('/wp-content/themes/min-design-theme/dist/fonts/CircularStd-Bold.svg#CircularStd-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

@font-face {
  font-family:'Circular Std';
  src: url('/wp-content/themes/min-design-theme/dist/fonts/CircularStd-Book.eot');
  src: url('/wp-content/themes/min-design-theme/dist/fonts/CircularStd-Book.eot?#iefix') format('embedded-opentype'),
  url('/wp-content/themes/min-design-theme/dist/fonts/CircularStd-Book.woff2') format('woff2'),
  url('/wp-content/themes/min-design-theme/dist/fonts/CircularStd-Book.woff') format('woff'),
  url('/wp-content/themes/min-design-theme/dist/fonts/CircularStd-Book.svg#CircularStd-Book') format('svg');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

$min_design_font : 'Circular Std';