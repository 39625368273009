.page-template-template-featured_projects {

  .page-section-header {
    margin-bottom: 40px;
  }
  .intro {
    color: $min_design-blue;
    margin-bottom: 36px;
  }

  .main {
    padding-right: 18px;
    padding-left: 18px;

    @include screen-sm() {
      padding-right: 27px;
      padding-left: 27px;
    }
    h2 {
      font-size: 25px;
      margin-bottom: 62px;
      @include screen-md() {
        font-size: 40px;
      }

    }
    .category-section {
      margin-top: 72px;
      margin-bottom: 27px;
    }
    & > .category-section ~ .category-section {
      // these selectors apply rules to
      // all but the first one
      margin-top: 189px;
      @include screen-md() {
        margin-top: 153px;
      }
    }
  }
}

