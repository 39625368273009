// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$min_design_blue_2 :        rgba(0,174,239, 1);
$min_design-blue :          rgba(30,129,186,1);
$min_design-orange :        rgba(222,77,38,1);
$min_design-red :           rgba(222,77,38,1);
$min_design-gray-light :    rgba(237,237,230,1);
$min_design-gray :					rgba(170,170,165,1);
$min_design-gray-medium :		rgba(101,101,94,1);
$min_design-gray-dark :     rgba(94,91,86,1);
$min_design-gray-darker :   rgba(51,51,47,1);
$min_design-black :					rgba(0,0,0,1);
$min_design-pink :          rgba(255,123,170,1);

$brand-primary :        $min_design-blue;
$text-color:            $min_design-gray-dark;
$headings-color:        $min_design-black;

$font-family-sans-serif : $min_design_font;
$headings-font-family : $min_design_font;