.project-tiles-container {
  width: 100%;
  @include screen-md() {
    display: flex;
    -webkit-flex-wrap: wrap;
    display: -webkit-flex;
    flex-wrap: wrap;
    flex-direction: row;
    position: relative;
  }
  .project-tile-container {
    @include screen-md() {
      /* flex: 0 0 calc(50% - 20px); doesn't work in IE */
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      width: calc(50% - 18px);

      &:nth-child(odd) {
        margin-right: 18px;
      }
      &:nth-child(even) {
        margin-left: 18px;
      }
    }
  }
}
.project-tile-container {
  position: relative;
  .new-badge {
    background-color: $min_design-red;
    color: white;
    font-weight: 700;
    left: 10px;
    padding: 5px 18px;
    position: absolute;
    top: 10px;
    -webkit-clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  }
  &.project-bg {
    // background-color: #efefef;
  }
  .project-bg {
    color: white;
    font-weight: normal;
  }
  margin-bottom: 36px;

  a {
    height: inherit;
    display: block;
  }

  @keyframes fadeUpIn {
      from {top:100px; opacity: 0;}
      to {top:0px; opacity: 1;}
   }

   .fadeUpIn {
      animation-name: fadeUpIn;
   }

  .project-tile-content {

    margin-bottom: 90px;

    @include screen-md() {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      margin-bottom: 0px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0,0,0,0.8);
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    &:hover {
      opacity: 1;
    }
    &:hover .project-tile-title {
      opacity: 1;
    }
    @include screen-md() {
      &:hover .project-tile-title {
        // animation
        transform: translateY(0px);
        transition: transform 0.2s ease-in-out;
      }
    }

    .contents {
      width: 100%;
      margin: 10px auto;
      @include screen-md() {
        margin: 0;
        padding: 20px 40px;
      }

    }

    .project-tile-title {
      font-family: $min_design_font;
      font-weight: 700;
      font-size: 18px;
      color: black;
      //animation
      transform: translateY(15px);
      transition: transform 0.3s ease-in-out;
      // animation-duration: 3s;
      // animation-fill-mode: both;
      @include screen-md() {
        opacity: 0;
        color: white;
        margin: 0;
        padding: 0;
        font-size: 24px;
      }
      @include screen-md() {
        font-size: 30px;
      }
    }
  }



  .project-category {
    text-transform: uppercase;
    color: $min_design-gray-light;
    font-size: 12px;
    @include screen-md() {
      font-size: 16px;
    }
  }
}
