.blog {
  .page-section-header {
    margin-bottom: 45px;
  }
  .news-item-container {
    width: 100%;
    @include screen-sm() {
      //display: flex;
    }
    .news-item {
      @include screen-sm() {
        display: flex;
        margin-bottom: 45px;
      }
      .news-item-featured-image {
        margin-bottom: 27px;
        @include screen-sm() {
          flex: 0 0 25%;
          margin-bottom: 45px;
          max-width: 25%;
          padding-right: 15px;
        }
      }
      .news-item-content {
        margin-bottom: 90px;
        font-size: 15px;
        @include screen-sm() {
        font-size: 18px;
          flex: 0 0 75%;
          padding-left: 15px;
          margin-bottom: 45px;
          max-width: 75%;
        }
        h2 {
          margin: -9px 0 9px 0;
          a {
            color: #000;
            display: inline-block;
            font-size: 20px;
            font-weight: 700;
            @include screen-sm() {
              font-size: 26px;
            }
            &:hover {
              color: $min_design-pink;
            }
          }
        }
      }
    }
  }
}

.single-post {
  article {
    margin-bottom: 100px;
    width: 100%;
    max-width: 100%;
  }
  .project-image {
    margin-top: 18px;
    @include screen-sm() {
      margin-top: 54px;
    }
  }
  .news-post-container {
    margin-top: 45px;
    width: 100%;
    max-width: 100%;
    @include screen-sm() {
      display: flex;
    }

    .post-meta {
      @include screen-sm() {
        flex: 0 0 calc(30% - 15px);
        max-width: calc(30% - 15px);
        margin-right: 15px;
      }
      ul.news-meta-list {
        margin: 0;
        padding: 0;
        li {
          font-size: 14px;
          list-style: none;
          border-top: 1px solid #b3b3b3;
          padding: 15px 5px;
          span {
            display: block;
            font-size: 14px;
          }
          span:not(.heading) {
            font-size: 16px;
            @include screen-sm() {
              font-size: 20px;
            }
          }
          .heading {
            font-weight: bold;
          }
        }
      }
    }
    .post-content {
      font-size: 18px;
      @include screen-sm() {
        flex: 0 0 calc(70% - 15px);
        max-width: calc(70% - 15px);
        margin-left: 15px;
      }
      h1.entry-title {
        font-size: 38px;
        margin-top: 27px;
        @include screen-sm() {
          margin: -7px 0 18px 0;
        }
      }
      p {
        font-size: 16px;
        @include screen-sm() {
          font-size: 20px;
        }
      }
      img {
        margin: 0 auto 36px;
        // margin-top: 18px;
        @include screen-sm() {
          width: auto;
          &.alignright {
            margin-left: 18px;
          }
          &.alignleft {
            margin-right: 18px;
          }
        }
      }
      div.wp-caption {
        border: 0;
        padding: 0;
        @include screen-sm() {
          width: auto;
          &.alignright {
            margin-left: 18px;
          }
          &.alignleft {
            margin-right: 18px;
          }
        }
        .wp-caption-text {
          color: $min_design-gray-dark;
          display: inline-block;
          font-size: 15px;
          font-weight: 700;
          line-height: 1.4;
          padding-left: 9px;
          padding-right: 9px;
          text-align: center;
          width: 100%;
          @include screen-md() {
            padding-left: 27px;
            padding-right: 27px;
          }
          @include screen-sm() {
            font-size: 18px;
          }
        }
      }
    }
  }
  .news-nav {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    svg#upScrollButton {
      transform: rotate(180deg);
    }

    svg#upScrollButton,
    svg#downScrollButton {
      pointer-events: auto;
      height: 35px;
      margin-left: 15px;
      text-decoration: none;
      transition: opacity 0.2s ease-in-out;
      width: 35px;
      @include screen-sm() {
        height: 50px;
        width: 50px;
      }

      &:hover {
        cursor: pointer;
        cursor: hand;
      }

      .scroll-circle {
        fill: hsla(60,3%,66%,.1);
        stroke-miterlimit: 10;
        transition: fill 0.2s ease-in-out;
      }

      &:hover .scroll-circle {
        fill: hsla(60,3%,66%,.3);
        transition: fill 0.2s ease-in-out;
      }

    }

  }
}
