.page-template-template-project-category-php {

  .main {
    padding: 0 18px;
    @include screen-sm() {
      padding: 0 27px;
    }
  }

  .project-category-container {
  	margin-top: 54px;
  }

$screen-min-height: 580px;
$screen-max-height: 800px;
$grid-max-height: 80vh;

  .project-category-row {

      height: $grid-max-height;
      min-height: $screen-min-height;
      max-height:$screen-max-height;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;

      .project-category-image-link {
        display: inline-block;
        width: 100%;
        @include screen-md() {
          width: auto;
        }
      }

      .project-category-image {
        background-position: 0% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 300px;
        width: 100%;

        @include screen-xs() {
          height: 375px;
        }
        @include screen-md() {
          background-size: contain;
          height: 450px;
          width: 600px;
        }
        @include screen-lg() {
          height: 525px;
          width: 700px;
        }
        @include screen-xl() {
          height: 600px;
          width: 1000px;
        }
      }

      &:nth-child(even) {
          justify-content: flex-end;
          .project-category-image {
            background-position: right 50%;
          }
      }

      .project-category-meta {
          left: 0;
          text-align: center;
          position: absolute;
          width: 100%;

          $opacity-off: 0.8;
          $opacity-over: 0;

          .project-category-title {
            // font-size: 6.5vw;
            color: rgba(0,0,0,.8);
            font-weight: 400;
            line-height: 1.27;
            text-decoration: none;
            @extend %h1-font-size;
            transition: all .2s;
            &:hover {
              color: $min_design-pink ;
            }

            @include screen-sm() {
              padding: 0 20px 10px;
            }

            &.change-color {
              span {
                // background: rgba(0,0,0,$opacity-off);
                // box-shadow: 10px 0 0 rgba(0,0,0,$opacity-off), -10px 0 0 rgba(0,0,0,$opacity-off);
                color: rgba(255,255,255,.8);
                // padding: 0px 5px;
                // position: relative;
                transition: all .2s;
              }
              &:hover span {
                // background: rgba(0,0,0,$opacity-over);
                // box-shadow: 10px 0 0 rgba(0,0,0,$opacity-over), -10px 0 0 rgba(0,0,0,$opacity-over);
                color: $min_design-pink ;
                // text-decoration: none;
                transition: all .2s;
              }
            }

          }

          .project-tags {
            a {
              border-bottom: 1px solid rgba(0,0,0,0.2);
              // color: rgba(0,0,0,0.2);
              font-size: 1.6rem;
              margin: 0 7px;
              padding: 3px;
              text-decoration: none;
              text-transform: capitalize;
              transition: all .2s;
              &:hover {
                border-bottom-color: rgba(0,0,0,0.8);
                color: rgba(0,0,0,0.8);
                transition: all .2s;
              }
            }
            .change-color {
              // border-bottom-color: rgba(255,255,255,0.8);
              color: rgba(255,255,255,0.8);
              transition: all .2s;
            }

          }
      }
  }

  .category-grid {
    @media(min-width: $screen-sm-min) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: 100vh; // minmax(270px,50vh);
      @media (min-height: $screen-min-height) {
        grid-auto-rows: minmax(270px,$grid-max-height);
      }
    }
  }

  .category-grid-item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    .project-image {
      @include screen-sm() {
        max-width: 40vw;
      }
      @include screen-md() {
        max-width: 35vw;
      }
      @include screen-lg() {
        max-width: 30vw;
      }
    }
    &:nth-child(odd) .project-image {
      @include screen-sm() {
        margin-left: auto;
        margin-right: 5vw;
      }
      // border:red 2px solid;
    }
    &:nth-child(even) .project-image {
      @include screen-sm() {
        margin-right: auto;
        margin-left: 5vw;
        margin-top: 100vh;
        @media (min-height: $screen-min-height) {
          margin-top: $grid-max-height;
        }
      }
    }
  }

  .project-category-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 27px 0 10px 0;
    li {
      margin: 10px;
    }
    &.change-color {
      li a {
        color: white;
        &:hover {
          color: black;
          transition: all .2s;
        }
      }
    }
  }

}
